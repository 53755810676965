<template>
  <tr class="table__row">
    <td class="table__cell px-2 py-3">
      <strong>{{ formattedType }}</strong>
    </td>
    <td class="table__cell px-2 py-3">
      <span>{{ addedBy }}</span>
    </td>
    <td class="table__cell --center px-2 py-3">
      <span>{{ additionDate }}</span>
    </td>
    <td class="table__cell --center px-2 py-3">
      <span>{{ $helpers.capFirstLetter(formattedStatus) }}</span>
    </td>
    <td v-if="action == 'get'" class="table__cell --center px-2 py-3">
      <a :href="getTLLink(id)" target="_blank" class="btn --blue --full-sm">Pobierz</a>
    </td>
    <td v-else-if="action == 'none'" class="table__cell --center px-2 py-3">
      <span></span>
    </td>
    <td v-else class="table__cell --center px-2 py-3">
      <router-link :to="getDocumentsViewLink(id)">{{
          $t("Zobacz")
        }}</router-link>
    </td>
  </tr>
</template>

<script>
import { formatDocumentType, formatDocumentStatus } from "@/utils/utils";
export default {
  props: ["type", "addedBy", "additionDate", "status", "id", "action"],
  computed: {
    formattedType() {
      return this.$t(formatDocumentType(this.type, this.$mode));
    },
    formattedStatus() {
      return this.$t(formatDocumentStatus(this.status));
    },
  },

  methods: {
  getTLLink(id) {
    return "/api/documents/getResourceTLFile/id/" + id;
  },
  getDocumentsViewLink(id) {
    return "/account/documents/details/" + id;
  }}
};

</script>