import axios from "@/axios";

export default {
  namespaced: true,
  state() {
    return {
      messageFlowMailsDetails: []
    }
  },
  getters: {
    all(state) {
      return state.messageFlowMailsDetails;
    },
  },
  mutations: {
    SET_MESSAGE_FLOW_MAILS_DETAILS(state, { messageFlowMailsDetails }) {
      state.messageFlowMailsDetails = [...messageFlowMailsDetails];
    }
  },
  actions: {
    fetchMessageFlowMailsDetails(context, { dateStart, dateEnd }) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get(`/api/balance/getMessageFlowMails/from/${dateStart}/to/${dateEnd}/details/true`
        ).then((res) => {
          context.commit("SET_MESSAGE_FLOW_MAILS_DETAILS", { messageFlowMailsDetails: res.data.mails });
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true)
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error)
        })
      })
    }
  }
}