import axios from "@/axios";
import errorHandler from "@/store/error_handler";

export default {
    namespaced: true,
    state() {
        return {
            results: [],
            errorMessage: '',
            successMessage: '',
            accountsIdoPayments: null
        }
    },
    mutations: {
        SET_RESULTS(state, {result}) {
            state.results = result;
        },
        SET_ERROR_MESSAGE(state, errorMessage) {
            state.errorMessage = errorMessage;
        },
        SET_SUCCESS_MESSAGE(state, successMessage) {
            state.successMessage = successMessage;
        }
    },
    getters: {
        results(state) {
            return state.results;
        },
        errorMessage(state) {
            return state.errorMessage;
        },
        successMessage(state) {
            return state.successMessage;
        }
    },
    actions: {
        fetchDataConfiguration(context) {
            context.dispatch("toggleLoader", true, {root: true});
            context.commit('SET_SUCCESS_MESSAGE', '');
            let formData = new FormData();
            return new Promise((resolve, reject) => {
                axios.post("/api/multicurrencies/get-configuration-data", formData).then((res) => {
                    if (res.data.response.result === 'error') {
                        let errorMessage = errorHandler({code: res.data.response.error});
                        context.commit('SET_ERROR_MESSAGE', errorMessage.message);
                        context.dispatch("toggleLoader", false, {root: true});
                        resolve(false);
                    } else {
                        context.commit("SET_RESULTS", {result: res.data.response});
                        context.dispatch("toggleLoader", false, {root: true});
                        context.commit('SET_ERROR_MESSAGE', '');
                        resolve(true);
                    }
                }).catch((error) => {
                    context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, {root: true});
                    context.dispatch("toggleLoader", false, {root: true});
                    reject(error);
                })
            })
        },
        saveConfiguration(context, {mutlicurrencies_configuration}) {
            context.dispatch("toggleLoader", true, {root: true});
            let formData = new FormData();
            context.commit('SET_SUCCESS_MESSAGE', '');
            formData.append("mutlicurrencies_configuration", JSON.stringify(mutlicurrencies_configuration));
            return new Promise((resolve, reject) => {
                axios.post("/api/multicurrencies/save-configuration-data", formData).then((res) => {
                    if (res.data.response.result === 'error') {
                        let errorMessage = errorHandler({code: res.data.response.error});
                        context.commit('SET_ERROR_MESSAGE', errorMessage.message);
                        context.dispatch("toggleLoader", false, {root: true});
                        resolve(false);
                    } else {
                        context.dispatch('fetchDataConfiguration').then(response => {
                            context.dispatch("toggleLoader", false, {root: true});
                            resolve(response);
                        });
                        context.commit('SET_ERROR_MESSAGE', '');
                        context.commit('SET_SUCCESS_MESSAGE', 'KonfiguracjaZostalaPomyslnieZapisana');
                    }
                }).catch((error) => {
                    context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, {root: true});
                    context.dispatch("toggleLoader", false, {root: true});
                    reject(error);
                })
            })
        },
    }
}