export default {
  path: '/account',
  name: 'Account',
  meta: { breadCrumb: 'TwojeKonto' },
  component: () => import('@/views/account/Account.vue'),
  children: [
    {
      meta: { breadCrumb: 'StanDzialaniaUslugi' },
      path: 'service_status',
      component: () => import('@/views/account/ServiceStatus.vue'),
    },
    {
      meta: { breadCrumb: 'Dokumenty' },
      path: 'documents',
      component: () => import('@/views/account/Documents.vue'),
    },
    {
      meta: { breadCrumb: 'SzczegolyDokumentu' },
      path: 'documents/details/:id',
      component: () => import('@/views/account/DocumentsDetails.vue'),
    },
    {
      meta: { breadCrumb: 'Umowa2' },
      path: 'documents/agreement',
      component: () => import('@/views/account/Agreement.vue'),
    },
    {
      meta: { breadCrumb: 'ZarzadzanieKontem' },
      path: 'settings',
      component: () => import('@/views/account/Settings.vue'),
    },
    {
      meta: { breadCrumb: 'Abonament' },
      path: 'subscription',
      component: () => import('@/views/account/Subscription.vue'),
    },
    {
      meta: { breadCrumb: 'Cesja' },
      path: 'documents/cession',
      component: () => import('@/views/account/Cession.vue'),
    },
    {
      meta: { breadCrumb: 'Wypowiedzenie' },
      path: 'documents/termination',
      component: () => import('@/views/account/Termination.vue'),
    },
    {
      meta: { breadCrumb: 'AnkietaKYCAML' },
      path: 'documents/onboarding',
      component: () => import('@/views/account/Onboarding.vue'),
    },
    {
      meta: { breadCrumb: 'ZarzadzanieKontem' },
      path: 'settings',
      component: () => import('@/views/account/Settings.vue'),
    },
    {
      meta: { breadCrumb: 'ListyWeryfikacyjne' },
      path: 'verification_letters',
      component: () => import('@/views/account/VerificationLetters.vue'),

    },
    {
      meta: { breadCrumb: 'Umowa Idopayments' },
      path: 'transactionLink/idopayOnboarding',
      component: () => import('@/views/account/transactionLink/idopayOnboarding.vue'),

    },
  ]

};