import messageFlowMailsDetailsModule from './messageFlowMailsDetails';
import axios from "@/axios";

export default {
  modules: {
    messageFlowMailsDetails: messageFlowMailsDetailsModule
  },
  namespaced: true,
  state() {
    return {
      messageFlowMailsDates: {},
      total: {},
    }
  },
  getters: {
    all(state) {
      return state.messageFlowMailsDates;
    },
    total(state) {
      return state.total
    }
  },
  mutations: {
    SET_MESSAGE_FLOW_MAILS_DATES(state, { messageFlowMails }) {
      state.messageFlowMailsDates = { ...messageFlowMails }
    },
    SET_TOTAL(state, { total }) {
      state.total = { ...total }
    },
    SET_PAGES(state, { pages }) {
      state.pages = { ...pages }
    }
  },
  actions: {
    fetchMessageFlowMailsDates(context, { dateStart, dateEnd }) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get(`/api/balance/getMessageFlowMails/from/${dateStart}/to/${dateEnd}/limit/20`
        ).then((res) => {
          context.commit("SET_TOTAL", { total: res.data.summary })
          delete res.data.summary
          context.commit("SET_MESSAGE_FLOW_MAILS_DATES", { messageFlowMails: res.data });
          context.commit("SET_PAGES", { pages: Math.ceil(parseInt(res.data.allRecords) / 20) });
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true)
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error)
        })
      })
    }
  }
}