import { createStore } from 'vuex';
import errorHandler from "./error_handler.js";
import supportTicketListModule from './supportTicketList';
import supportSubjectsModule from './supportSubjects';
import invoicesListModule from './invoicesList';
import invoicesDetailsModule from './invoicesDetails';
import paymentMethodsModule from './paymentMethods';
import balanceHistoryModule from './balanceHistory';
import balanceHistoryDetailsModule from './balanceHistoryDetails';
import balanceTasksConfirmModule from './balanceTasksConfirm';
import balanceSmsModule from './balanceSms'
import balanceFreshmailModule from './balanceFreshmail';
import balanceMessageFlowMailsModule from './balanceMessageFlowMails';
import balanceIdosellBrokerModule from './balanceIdosellBroker';
import balanceIdopayModule from './balanceIdopay';
import checkoutOrdersModule from './checkoutOrders';
import accountServiceStatusModule from './accountServiceStatus';
import accountDocumentsModule from './accountDocuments';
import accountSettingsModule from './accountSettings';
import accountSubscriptionModule from './accountSubscription';
import accountVerificationLettersModule from './accountVerificationLetters';
import paymentsListModule from './paymentsList'
import paymentsDetailsModule from './paymentsDetails'
import paymentsWithdrawalModule from './paymentsWithdrawal';
import paymentsWithdrawalPendingModule from './paymentsWithdrawalPending';
import paymentsWithdrawalHistoryModule from './paymentsWithdrawalHistory';
import paymentsWithdrawalAccountsModule from './paymentsWithdrawalAccounts';
import paymentsWithdrawalAutomatizationModule from './paymentsWithdrawalAutomatization';
import paymentsWithdrawalCurrenciesConfigurationModule from './paymentsWithdrawalCurrenciesConfiguration';
import supportTicketModule from "./supportTicket";
import supportTicketNewModule from "./supportTicketNew";
import dashboardModule from './dashboard';
import shoprenterGoModule from './shoprenterGo';
import axios from '@/axios';

export default createStore({
  modules: {
    supportTicketList: supportTicketListModule,
    supportSubjects: supportSubjectsModule,
    paymentMethods: paymentMethodsModule,
    invoicesList: invoicesListModule,
    invoicesDetails: invoicesDetailsModule,
    balanceHistory: balanceHistoryModule,
    balanceHistoryDetails: balanceHistoryDetailsModule,
    balanceTasksConfirm: balanceTasksConfirmModule,
    balanceSms: balanceSmsModule,
    balanceFreshmail: balanceFreshmailModule,
    balanceMessageFlowMails: balanceMessageFlowMailsModule,
    balanceIdosellBroker: balanceIdosellBrokerModule,
    balanceIdopay: balanceIdopayModule,
    checkoutOrders: checkoutOrdersModule,
    accountServiceStatus: accountServiceStatusModule,
    accountDocuments: accountDocumentsModule,
    accountSettings: accountSettingsModule,
    accountSubscription: accountSubscriptionModule,
    accountVerificationLetters: accountVerificationLettersModule,
    paymentsList: paymentsListModule,
    paymentsDetails: paymentsDetailsModule,
    paymentsWithdrawal: paymentsWithdrawalModule,
    paymentsWithdrawalPending: paymentsWithdrawalPendingModule,
    paymentsWithdrawalHistory: paymentsWithdrawalHistoryModule,
    paymentsWithdrawalAccounts: paymentsWithdrawalAccountsModule,
    paymentsWithdrawalAutomatization: paymentsWithdrawalAutomatizationModule,
    paymentsWithdrawalCurrenciesConfiguration: paymentsWithdrawalCurrenciesConfigurationModule,
    supportTicket: supportTicketModule,
    supportTicketNew: supportTicketNewModule,
    dashboard: dashboardModule,
    shoprenterGo: shoprenterGoModule,
  },

  state() {
    return {
      isMenuOpen: false,
      isContentLoading: false,
      errorOpen: false,
      errorType: "warning",
      errorLabel: "Ostrzezenie",
      errorMessage: "",
      errorParam1: "",
      errorParam2: "",
      errorParam3: "",
    }
  },
  mutations: {
    TOGGLE_MENU(state, payload) {
      if (payload) {
        state.isMenuOpen = payload;
      } else {
        state.isMenuOpen = !state.isMenuOpen;
      }
    },
    TOGGLE_LOADER(state, payload) {
      if (payload !== undefined) {
        state.isContentLoading = payload;
      } else {
        state.isContentLoading = !state.isContentLoading;
      }
    },
    SET_ERROR(state, { type, label, message, param1, param2, param3 }) {
      state.errorLabel = label;
      state.errorType = type;
      state.errorMessage = message;
      state.errorParam1 = param1;
      state.errorParam2 = param2;
      state.errorParam3 = param3;
    },
    TOGGLE_ERROR(state, payload) {
      if (payload !== undefined) {
        state.errorOpen = payload;
      } else {
        state.errorOpen = !state.errorOpen;
      }
    }
  },
  actions: {
    toggleMenu(context, payload) {
      context.commit('TOGGLE_MENU', payload);
    },
    toggleLoader(context, payload) {
      context.commit('TOGGLE_LOADER', payload);
      if (payload) {
        context.commit("TOGGLE_ERROR", false);
      }
    },
    internalServerError(context, error) {
      context.commit("TOGGLE_ERROR", false);
      context.commit("SET_ERROR", errorHandler(error));
      context.commit("TOGGLE_ERROR", true);
    },
    hideError(context) {
      context.commit("TOGGLE_ERROR", false);
    },
    logoutUser(context, { lang, mode }) {
      context.dispatch("toggleLoader", true, { root: true });
      axios.get("/api/Client/logout").then(() => {
        window.location.href = `/${lang}/logout_${mode}.html`;
      }).catch((error) => {
        context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
        context.dispatch("toggleLoader", false, { root: true });
      })
    }
  },
  getters: {
    menuState(state) {
      return state.isMenuOpen;
    },
    loaderState(state) {
      return state.isContentLoading;
    },
    alertState(state) {
      return state.errorOpen;
    },
    errorType(state) {
      return state.errorType;
    },
    errorLabel(state) {
      return state.errorLabel;
    },
    errorMessage(state) {
      return state.errorMessage;
    },
    errorParam1(state) {
      return state.errorParam1;
    },
    errorParam2(state) {
      return state.errorParam2;
    },
    errorParam3(state) {
      return state.errorParam3;
    },
  },
})
